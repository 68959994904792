@import url('https://fonts.googleapis.com/css?family=Lato');
html, body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Lato', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  display: flex;
}

#root>div {
  flex: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}